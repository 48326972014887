.MigrationLogoutContainer {
    display: flex;
    align-items: center;
}

.MigrationErrorTimerImage {
    margin: 3px 0px 3px 3px;
}

.MigrationMessageDivider {
    margin: 0px 4px;
}

.MigrationTextContainer {
    font-size: 14px;
    margin-bottom: 4px;
    font-weight: 600;
}

.MigrationErrorButton {
    color: #fff;
    border-color: #0033ab;
    border-radius: 3px;
    background-color: #0033ab;
    padding: 3px 10px;
    font-size: 14px;
    font-family: "Helvetica Neue",Helvetica,Arial,sans-serif;
    line-height: 18px;
    cursor: pointer;
    text-transform: none;
    border: #0033ab;
    height: 100%;
    box-shadow: 0px 1px 5px 0px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 3px 1px -2px rgba(0,0,0,0.12);
}